import { default as icons_45pagerRFhlv8b97Meta } from "/app/anybill-partner-portal-v3/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as indexVY3str7LLuMeta } from "/app/anybill-partner-portal-v3/pages/activation/index.vue?macro=true";
import { default as ReceiptsExportDialog7ku5unsXiSMeta } from "/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsExportDialog.vue?macro=true";
import { default as ReceiptsFilterDialoguQjMM0ww6TMeta } from "/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsFilterDialog.vue?macro=true";
import { default as RFStoreComponent2Y0LBXKJt9Meta } from "/app/anybill-partner-portal-v3/pages/archive/components/RFStoreComponent.vue?macro=true";
import { default as defaultViewy2AXycr0KXMeta } from "/app/anybill-partner-portal-v3/pages/archive/defaultView.vue?macro=true";
import { default as exportsyWzPrU2DVOMeta } from "/app/anybill-partner-portal-v3/pages/archive/exports.vue?macro=true";
import { default as receipts1gQeuHFW4fMeta } from "/app/anybill-partner-portal-v3/pages/archive/receipts.vue?macro=true";
import { default as ApiUserDataCard4VGIN8auNJMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/ApiUserDataCard.vue?macro=true";
import { default as CustomerOverviewCard0PWS345T3WMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/CustomerOverviewCard.vue?macro=true";
import { default as AUDConfirmDeleteDialogJUhDZ3hGCIMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDConfirmDeleteDialog.vue?macro=true";
import { default as AUDCreateApiUserDialogDjdRA6NfRrMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDCreateApiUserDialog.vue?macro=true";
import { default as COCCustomerEditDialogrua0Wpk2UqMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/COCCustomerEditDialog.vue?macro=true";
import { default as StoreDataCardDxCr9RNcNiMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/StoreDataCard.vue?macro=true";
import { default as indexAqY5n9YaViMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/index.vue?macro=true";
import { default as DevBulkRemoveDialogWlV5CVz77KMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/components/dialogs/developer/DevBulkRemoveDialog.vue?macro=true";
import { default as indexKd3DktJiMdMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/index.vue?macro=true";
import { default as CmProfileA1yEb0XHU2Meta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmProfile.vue?macro=true";
import { default as CMStoresvLMfgnrVW1Meta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CMStores.vue?macro=true";
import { default as CmSuccessDialog5s7MWO8mjUMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmSuccessDialog.vue?macro=true";
import { default as CreateIndividualLinkDialogLKCmUStbu4Meta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateIndividualLinkDialog.vue?macro=true";
import { default as CreateLinkDialogPRtnyACPyAMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateLinkDialog.vue?macro=true";
import { default as DeleteOnboardingDialogftMKxuaG2EMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/DeleteOnboardingDialog.vue?macro=true";
import { default as ImageUploaderWhGD29AYgGMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/ImageUploader.vue?macro=true";
import { default as indexV4GLMs1u6JMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/index.vue?macro=true";
import { default as SelectOnboardingMethodDialog56W7bwbaMSMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/SelectOnboardingMethodDialog.vue?macro=true";
import { default as CalendarSelectionCardaNHdbXZVGqMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/CalendarSelectionCard.vue?macro=true";
import { default as DisplayAdClicksCardQ878Az4OPnMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdClicksCard.vue?macro=true";
import { default as DisplayAdImpressionsCardhT4679g1T6Meta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdImpressionsCard.vue?macro=true";
import { default as EnvironmentCardWLzRoC2XOnMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/EnvironmentCard.vue?macro=true";
import { default as NewsletterCardtrjKc7fTRrMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/NewsletterCard.vue?macro=true";
import { default as OverallBillsCard3E2oswIMaZMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/OverallBillsCard.vue?macro=true";
import { default as PrintedReceiptsCardVmupI378E0Meta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PrintedReceiptsCard.vue?macro=true";
import { default as SavedCostCardfUplgslN1dMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/SavedCostCard.vue?macro=true";
import { default as GenericDashboardCardQNiMymm9tXMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/GenericDashboardCard.vue?macro=true";
import { default as StoreSelectionFilterMenurtgxeCzuAHMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/StoreSelectionFilterMenu.vue?macro=true";
import { default as VendorCustomerFilterMenuGkF1U6IXQzMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/VendorCustomerFilterMenu.vue?macro=true";
import { default as indexNHxcIQcSUMMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/index.vue?macro=true";
import { default as indexpvqpfwwnEpMeta } from "/app/anybill-partner-portal-v3/pages/distributor-management/index.vue?macro=true";
import { default as SuccessDialolgCRBy1isJB4Meta } from "/app/anybill-partner-portal-v3/pages/distributor-management/SuccessDialolg.vue?macro=true";
import { default as indexU5JQntZFlVMeta } from "/app/anybill-partner-portal-v3/pages/documents/index.vue?macro=true";
import { default as index48k5wZRNVmMeta } from "/app/anybill-partner-portal-v3/pages/index.vue?macro=true";
import { default as indexh31L96ffG1Meta } from "/app/anybill-partner-portal-v3/pages/login/index.vue?macro=true";
import { default as ContentManagementOverviewc7nprs6BYhMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/ContentManagementOverview.vue?macro=true";
import { default as AdvancedSettingsArea9Ha8qnSqdOMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea.vue?macro=true";
import { default as ASChannelComponentmGUhBjKQxTMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent.vue?macro=true";
import { default as ASPeriodComponentYWflPq4xxeMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent.vue?macro=true";
import { default as ASPositionComponentB4wSGNaNA3Meta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent.vue?macro=true";
import { default as ASStoresComponentS6zjofvmdjMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent.vue?macro=true";
import { default as CMOBasicPackageDialogtDne4t8nqYMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOBasicPackageDialog.vue?macro=true";
import { default as CMOConflictDialogQWBHa87iqMMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOConflictDialog.vue?macro=true";
import { default as CMODeactivationDialogQ38CVSaidUMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODeactivationDialog.vue?macro=true";
import { default as CMODialogqCF9U2Ms5MMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialog.vue?macro=true";
import { default as CMODialogExtendedOptionTileJyEgx0OpVRMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile.vue?macro=true";
import { default as CMODialogRadioComponentBB2qqCz7bnMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogRadioComponent.vue?macro=true";
import { default as DAPGroupContentQt2kRP3BJSMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DAPGroupContent.vue?macro=true";
import { default as DisplayAdPreviewDialoggFUygnK8gnMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog.vue?macro=true";
import { default as indexqFcnEm6LmwMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/index.vue?macro=true";
import { default as customer_45mailsqZtLsmGdTjMeta } from "/app/anybill-partner-portal-v3/pages/marketing/customer-mails.vue?macro=true";
import { default as indexvbi9oftJbrMeta } from "/app/anybill-partner-portal-v3/pages/onboard/index.vue?macro=true";
import { default as BookingDialogOzxjSvfd8OMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingDialog.vue?macro=true";
import { default as BookingSuccessDialoguaQqnVm6ONMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingSuccessDialog.vue?macro=true";
import { default as PackagesAddressDialoghzD7mhPKJrMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesAddressDialog.vue?macro=true";
import { default as PackagesChangePaymentDialogRIO3WKq2TWMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesChangePaymentDialog.vue?macro=true";
import { default as PackagesFeatureListo0NWZeZFUaMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesFeatureList.vue?macro=true";
import { default as IndividualPricingCard5QbuV9GN36Meta } from "/app/anybill-partner-portal-v3/pages/packages/components/table/content/IndividualPricingCard.vue?macro=true";
import { default as PricingComparisonTablebL6HQ6vSLwMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/table/PricingComparisonTable.vue?macro=true";
import { default as PricingToggleSwitchurtGOIQsO8Meta } from "/app/anybill-partner-portal-v3/pages/packages/components/table/PricingToggleSwitch.vue?macro=true";
import { default as indexINR7CVYuoZMeta } from "/app/anybill-partner-portal-v3/pages/packages/index.vue?macro=true";
import { default as AnybillLogo8gJv9pCg2RMeta } from "/app/anybill-partner-portal-v3/pages/privacy/components/AnybillLogo.vue?macro=true";
import { default as PrivacyContentDeJh3dMbDqjrMeta } from "/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentDe.vue?macro=true";
import { default as PrivacyContentEnst0CRPG3sUMeta } from "/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentEn.vue?macro=true";
import { default as indexnewfnpMSUPMeta } from "/app/anybill-partner-portal-v3/pages/privacy/index.vue?macro=true";
import { default as AddressProfileCardx2N7GqFS1WMeta } from "/app/anybill-partner-portal-v3/pages/profile/components/AddressProfileCard.vue?macro=true";
import { default as VendorDialog4oZ6MGiynZMeta } from "/app/anybill-partner-portal-v3/pages/profile/components/dialogs/VendorDialog.vue?macro=true";
import { default as PaymentProfileCardhI6TYOToL5Meta } from "/app/anybill-partner-portal-v3/pages/profile/components/PaymentProfileCard.vue?macro=true";
import { default as VendorProfileCard7I1hEJaHGaMeta } from "/app/anybill-partner-portal-v3/pages/profile/components/VendorProfileCard.vue?macro=true";
import { default as indexTT1mCcF2JHMeta } from "/app/anybill-partner-portal-v3/pages/profile/index.vue?macro=true";
import { default as ChangePasswordDialogsTUvbBdhfPMeta } from "/app/anybill-partner-portal-v3/pages/settings/components/dialogs/ChangePasswordDialog.vue?macro=true";
import { default as SettingsPage7sSY9HNk1eMeta } from "/app/anybill-partner-portal-v3/pages/settings/components/SettingsPage.vue?macro=true";
import { default as index6qPAaaCFS2Meta } from "/app/anybill-partner-portal-v3/pages/settings/index.vue?macro=true";
import { default as AddStoreDialogh8EeTlxqEJMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/AddStoreDialog.vue?macro=true";
import { default as Step1PhXipTk1sdMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step1.vue?macro=true";
import { default as Step2eFD2fbijLxMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step2.vue?macro=true";
import { default as Step3mgHyEafFfqMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step3.vue?macro=true";
import { default as Step4r1VT0861gSMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step4.vue?macro=true";
import { default as indexhYF2GEGFAZMeta } from "/app/anybill-partner-portal-v3/pages/store-management/index.vue?macro=true";
import { default as EditRightsGroupsDialogQEEHnxGw7FMeta } from "/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditRightsGroupsDialog.vue?macro=true";
import { default as EditUserRightsDialogW7bfgADEokMeta } from "/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditUserRightsDialog.vue?macro=true";
import { default as indexXhAEDt5WGRMeta } from "/app/anybill-partner-portal-v3/pages/user-administration/index.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/app/anybill-partner-portal-v3/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: "activation___en",
    path: "/en/activation",
    component: () => import("/app/anybill-partner-portal-v3/pages/activation/index.vue").then(m => m.default || m)
  },
  {
    name: "activation___de",
    path: "/activation",
    component: () => import("/app/anybill-partner-portal-v3/pages/activation/index.vue").then(m => m.default || m)
  },
  {
    name: "archive-components-ReceiptsExportDialog___en",
    path: "/en/archive/components/ReceiptsExportDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsExportDialog.vue").then(m => m.default || m)
  },
  {
    name: "archive-components-ReceiptsExportDialog___de",
    path: "/archive/components/ReceiptsExportDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsExportDialog.vue").then(m => m.default || m)
  },
  {
    name: "archive-components-ReceiptsFilterDialog___en",
    path: "/en/archive/components/ReceiptsFilterDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsFilterDialog.vue").then(m => m.default || m)
  },
  {
    name: "archive-components-ReceiptsFilterDialog___de",
    path: "/archive/components/ReceiptsFilterDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsFilterDialog.vue").then(m => m.default || m)
  },
  {
    name: "archive-components-RFStoreComponent___en",
    path: "/en/archive/components/RFStoreComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/RFStoreComponent.vue").then(m => m.default || m)
  },
  {
    name: "archive-components-RFStoreComponent___de",
    path: "/archive/components/RFStoreComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/RFStoreComponent.vue").then(m => m.default || m)
  },
  {
    name: "archive-defaultView___en",
    path: "/en/archive/defaultView",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/defaultView.vue").then(m => m.default || m)
  },
  {
    name: "archive-defaultView___de",
    path: "/archive/defaultView",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/defaultView.vue").then(m => m.default || m)
  },
  {
    name: "archive-exports___en",
    path: "/en/archive/exports",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/exports.vue").then(m => m.default || m)
  },
  {
    name: "archive-exports___de",
    path: "/archive/exports",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/exports.vue").then(m => m.default || m)
  },
  {
    name: "archive-receipts___en",
    path: "/en/archive/receipts",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/receipts.vue").then(m => m.default || m)
  },
  {
    name: "archive-receipts___de",
    path: "/archive/receipts",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/receipts.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-ApiUserDataCard___en",
    path: "/en/customer-management/:id()/components/ApiUserDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/ApiUserDataCard.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-ApiUserDataCard___de",
    path: "/customer-management/:id()/components/ApiUserDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/ApiUserDataCard.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-CustomerOverviewCard___en",
    path: "/en/customer-management/:id()/components/CustomerOverviewCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/CustomerOverviewCard.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-CustomerOverviewCard___de",
    path: "/customer-management/:id()/components/CustomerOverviewCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/CustomerOverviewCard.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-dialogs-AUDConfirmDeleteDialog___en",
    path: "/en/customer-management/:id()/components/dialogs/AUDConfirmDeleteDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDConfirmDeleteDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-dialogs-AUDConfirmDeleteDialog___de",
    path: "/customer-management/:id()/components/dialogs/AUDConfirmDeleteDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDConfirmDeleteDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-dialogs-AUDCreateApiUserDialog___en",
    path: "/en/customer-management/:id()/components/dialogs/AUDCreateApiUserDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDCreateApiUserDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-dialogs-AUDCreateApiUserDialog___de",
    path: "/customer-management/:id()/components/dialogs/AUDCreateApiUserDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDCreateApiUserDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-dialogs-COCCustomerEditDialog___en",
    path: "/en/customer-management/:id()/components/dialogs/COCCustomerEditDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/COCCustomerEditDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-dialogs-COCCustomerEditDialog___de",
    path: "/customer-management/:id()/components/dialogs/COCCustomerEditDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/COCCustomerEditDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-StoreDataCard___en",
    path: "/en/customer-management/:id()/components/StoreDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/StoreDataCard.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id-components-StoreDataCard___de",
    path: "/customer-management/:id()/components/StoreDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/StoreDataCard.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id___en",
    path: "/en/customer-management/:id()",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-id___de",
    path: "/customer-management/:id()",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-components-dialogs-developer-DevBulkRemoveDialog___en",
    path: "/en/customer-management/components/dialogs/developer/DevBulkRemoveDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/dialogs/developer/DevBulkRemoveDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-components-dialogs-developer-DevBulkRemoveDialog___de",
    path: "/customer-management/components/dialogs/developer/DevBulkRemoveDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/dialogs/developer/DevBulkRemoveDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management___en",
    path: "/en/customer-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-management___de",
    path: "/customer-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CmProfile___en",
    path: "/en/customer-management/onboarding/CmProfile",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmProfile.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CmProfile___de",
    path: "/customer-management/onboarding/CmProfile",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmProfile.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CMStores___en",
    path: "/en/customer-management/onboarding/CMStores",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CMStores.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CMStores___de",
    path: "/customer-management/onboarding/CMStores",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CMStores.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CmSuccessDialog___en",
    path: "/en/customer-management/onboarding/CmSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmSuccessDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CmSuccessDialog___de",
    path: "/customer-management/onboarding/CmSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmSuccessDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CreateIndividualLinkDialog___en",
    path: "/en/customer-management/onboarding/CreateIndividualLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateIndividualLinkDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CreateIndividualLinkDialog___de",
    path: "/customer-management/onboarding/CreateIndividualLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateIndividualLinkDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CreateLinkDialog___en",
    path: "/en/customer-management/onboarding/CreateLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateLinkDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-CreateLinkDialog___de",
    path: "/customer-management/onboarding/CreateLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateLinkDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-DeleteOnboardingDialog___en",
    path: "/en/customer-management/onboarding/DeleteOnboardingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/DeleteOnboardingDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-DeleteOnboardingDialog___de",
    path: "/customer-management/onboarding/DeleteOnboardingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/DeleteOnboardingDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-ImageUploader___en",
    path: "/en/customer-management/onboarding/ImageUploader",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/ImageUploader.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-ImageUploader___de",
    path: "/customer-management/onboarding/ImageUploader",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/ImageUploader.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding___en",
    path: "/en/customer-management/onboarding",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding___de",
    path: "/customer-management/onboarding",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-SelectOnboardingMethodDialog___en",
    path: "/en/customer-management/onboarding/SelectOnboardingMethodDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/SelectOnboardingMethodDialog.vue").then(m => m.default || m)
  },
  {
    name: "customer-management-onboarding-SelectOnboardingMethodDialog___de",
    path: "/customer-management/onboarding/SelectOnboardingMethodDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/SelectOnboardingMethodDialog.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-CalendarSelectionCard___en",
    path: "/en/dashboard/components/CalendarSelectionCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/CalendarSelectionCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-CalendarSelectionCard___de",
    path: "/dashboard/components/CalendarSelectionCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/CalendarSelectionCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-DisplayAdClicksCard___en",
    path: "/en/dashboard/components/cards/DisplayAdClicksCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdClicksCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-DisplayAdClicksCard___de",
    path: "/dashboard/components/cards/DisplayAdClicksCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdClicksCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-DisplayAdImpressionsCard___en",
    path: "/en/dashboard/components/cards/DisplayAdImpressionsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdImpressionsCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-DisplayAdImpressionsCard___de",
    path: "/dashboard/components/cards/DisplayAdImpressionsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdImpressionsCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-EnvironmentCard___en",
    path: "/en/dashboard/components/cards/EnvironmentCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/EnvironmentCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-EnvironmentCard___de",
    path: "/dashboard/components/cards/EnvironmentCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/EnvironmentCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-NewsletterCard___en",
    path: "/en/dashboard/components/cards/NewsletterCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/NewsletterCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-NewsletterCard___de",
    path: "/dashboard/components/cards/NewsletterCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/NewsletterCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-OverallBillsCard___en",
    path: "/en/dashboard/components/cards/OverallBillsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/OverallBillsCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-OverallBillsCard___de",
    path: "/dashboard/components/cards/OverallBillsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/OverallBillsCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-PrintedReceiptsCard___en",
    path: "/en/dashboard/components/cards/PrintedReceiptsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PrintedReceiptsCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-PrintedReceiptsCard___de",
    path: "/dashboard/components/cards/PrintedReceiptsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PrintedReceiptsCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-SavedCostCard___en",
    path: "/en/dashboard/components/cards/SavedCostCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/SavedCostCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-cards-SavedCostCard___de",
    path: "/dashboard/components/cards/SavedCostCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/SavedCostCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-GenericDashboardCard___en",
    path: "/en/dashboard/components/GenericDashboardCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/GenericDashboardCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-GenericDashboardCard___de",
    path: "/dashboard/components/GenericDashboardCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/GenericDashboardCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-StoreSelectionFilterMenu___en",
    path: "/en/dashboard/components/StoreSelectionFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/StoreSelectionFilterMenu.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-StoreSelectionFilterMenu___de",
    path: "/dashboard/components/StoreSelectionFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/StoreSelectionFilterMenu.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-VendorCustomerFilterMenu___en",
    path: "/en/dashboard/components/VendorCustomerFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/VendorCustomerFilterMenu.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-components-VendorCustomerFilterMenu___de",
    path: "/dashboard/components/VendorCustomerFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/VendorCustomerFilterMenu.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___de",
    path: "/dashboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "distributor-management___en",
    path: "/en/distributor-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/index.vue").then(m => m.default || m)
  },
  {
    name: "distributor-management___de",
    path: "/distributor-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/index.vue").then(m => m.default || m)
  },
  {
    name: "distributor-management-SuccessDialolg___en",
    path: "/en/distributor-management/SuccessDialolg",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/SuccessDialolg.vue").then(m => m.default || m)
  },
  {
    name: "distributor-management-SuccessDialolg___de",
    path: "/distributor-management/SuccessDialolg",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/SuccessDialolg.vue").then(m => m.default || m)
  },
  {
    name: "documents___en",
    path: "/en/documents",
    component: () => import("/app/anybill-partner-portal-v3/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "documents___de",
    path: "/documents",
    component: () => import("/app/anybill-partner-portal-v3/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/anybill-partner-portal-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/app/anybill-partner-portal-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/app/anybill-partner-portal-v3/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/login",
    component: () => import("/app/anybill-partner-portal-v3/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-ContentManagementOverview___en",
    path: "/en/marketing/content-management/components/ContentManagementOverview",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/ContentManagementOverview.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-ContentManagementOverview___de",
    path: "/marketing/content-management/components/ContentManagementOverview",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/ContentManagementOverview.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-AdvancedSettingsArea___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-AdvancedSettingsArea___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASChannelComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASChannelComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPeriodComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPeriodComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPositionComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPositionComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASStoresComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASStoresComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMOBasicPackageDialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMOBasicPackageDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOBasicPackageDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMOBasicPackageDialog___de",
    path: "/marketing/content-management/components/dialogs/CMOBasicPackageDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOBasicPackageDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMOConflictDialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMOConflictDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOConflictDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMOConflictDialog___de",
    path: "/marketing/content-management/components/dialogs/CMOConflictDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOConflictDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODeactivationDialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMODeactivationDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODeactivationDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODeactivationDialog___de",
    path: "/marketing/content-management/components/dialogs/CMODeactivationDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODeactivationDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMODialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialog___de",
    path: "/marketing/content-management/components/dialogs/CMODialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogExtendedOptionTile___en",
    path: "/en/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogExtendedOptionTile___de",
    path: "/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogRadioComponent___en",
    path: "/en/marketing/content-management/components/dialogs/CMODialogRadioComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogRadioComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogRadioComponent___de",
    path: "/marketing/content-management/components/dialogs/CMODialogRadioComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogRadioComponent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DAPGroupContent___en",
    path: "/en/marketing/content-management/components/dialogs/preview/DAPGroupContent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DAPGroupContent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DAPGroupContent___de",
    path: "/marketing/content-management/components/dialogs/preview/DAPGroupContent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DAPGroupContent.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DisplayAdPreviewDialog___en",
    path: "/en/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DisplayAdPreviewDialog___de",
    path: "/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management___en",
    path: "/en/marketing/content-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-content-management___de",
    path: "/marketing/content-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-customer-mails___en",
    path: "/en/marketing/customer-mails",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/customer-mails.vue").then(m => m.default || m)
  },
  {
    name: "marketing-customer-mails___de",
    path: "/marketing/customer-mails",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/customer-mails.vue").then(m => m.default || m)
  },
  {
    name: "onboard___en",
    path: "/en/onboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: "onboard___de",
    path: "/onboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-BookingDialog___en",
    path: "/en/packages/components/dialogs/BookingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-BookingDialog___de",
    path: "/packages/components/dialogs/BookingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-BookingSuccessDialog___en",
    path: "/en/packages/components/dialogs/BookingSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingSuccessDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-BookingSuccessDialog___de",
    path: "/packages/components/dialogs/BookingSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingSuccessDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-PackagesAddressDialog___en",
    path: "/en/packages/components/dialogs/PackagesAddressDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesAddressDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-PackagesAddressDialog___de",
    path: "/packages/components/dialogs/PackagesAddressDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesAddressDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-PackagesChangePaymentDialog___en",
    path: "/en/packages/components/dialogs/PackagesChangePaymentDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesChangePaymentDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-PackagesChangePaymentDialog___de",
    path: "/packages/components/dialogs/PackagesChangePaymentDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesChangePaymentDialog.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-PackagesFeatureList___en",
    path: "/en/packages/components/dialogs/PackagesFeatureList",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesFeatureList.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-dialogs-PackagesFeatureList___de",
    path: "/packages/components/dialogs/PackagesFeatureList",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesFeatureList.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-table-content-IndividualPricingCard___en",
    path: "/en/packages/components/table/content/IndividualPricingCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/content/IndividualPricingCard.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-table-content-IndividualPricingCard___de",
    path: "/packages/components/table/content/IndividualPricingCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/content/IndividualPricingCard.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-table-PricingComparisonTable___en",
    path: "/en/packages/components/table/PricingComparisonTable",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingComparisonTable.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-table-PricingComparisonTable___de",
    path: "/packages/components/table/PricingComparisonTable",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingComparisonTable.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-table-PricingToggleSwitch___en",
    path: "/en/packages/components/table/PricingToggleSwitch",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingToggleSwitch.vue").then(m => m.default || m)
  },
  {
    name: "packages-components-table-PricingToggleSwitch___de",
    path: "/packages/components/table/PricingToggleSwitch",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingToggleSwitch.vue").then(m => m.default || m)
  },
  {
    name: "packages___en",
    path: "/en/packages",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___de",
    path: "/packages",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-components-AnybillLogo___en",
    path: "/en/privacy/components/AnybillLogo",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/AnybillLogo.vue").then(m => m.default || m)
  },
  {
    name: "privacy-components-AnybillLogo___de",
    path: "/privacy/components/AnybillLogo",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/AnybillLogo.vue").then(m => m.default || m)
  },
  {
    name: "privacy-components-PrivacyContentDe___en",
    path: "/en/privacy/components/PrivacyContentDe",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentDe.vue").then(m => m.default || m)
  },
  {
    name: "privacy-components-PrivacyContentDe___de",
    path: "/privacy/components/PrivacyContentDe",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentDe.vue").then(m => m.default || m)
  },
  {
    name: "privacy-components-PrivacyContentEn___en",
    path: "/en/privacy/components/PrivacyContentEn",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentEn.vue").then(m => m.default || m)
  },
  {
    name: "privacy-components-PrivacyContentEn___de",
    path: "/privacy/components/PrivacyContentEn",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentEn.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    meta: indexnewfnpMSUPMeta || {},
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___de",
    path: "/privacy",
    meta: indexnewfnpMSUPMeta || {},
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-AddressProfileCard___en",
    path: "/en/profile/components/AddressProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/AddressProfileCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-AddressProfileCard___de",
    path: "/profile/components/AddressProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/AddressProfileCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-dialogs-VendorDialog___en",
    path: "/en/profile/components/dialogs/VendorDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/dialogs/VendorDialog.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-dialogs-VendorDialog___de",
    path: "/profile/components/dialogs/VendorDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/dialogs/VendorDialog.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PaymentProfileCard___en",
    path: "/en/profile/components/PaymentProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/PaymentProfileCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PaymentProfileCard___de",
    path: "/profile/components/PaymentProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/PaymentProfileCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VendorProfileCard___en",
    path: "/en/profile/components/VendorProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/VendorProfileCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VendorProfileCard___de",
    path: "/profile/components/VendorProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/VendorProfileCard.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___de",
    path: "/profile",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-components-dialogs-ChangePasswordDialog___en",
    path: "/en/settings/components/dialogs/ChangePasswordDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/dialogs/ChangePasswordDialog.vue").then(m => m.default || m)
  },
  {
    name: "settings-components-dialogs-ChangePasswordDialog___de",
    path: "/settings/components/dialogs/ChangePasswordDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/dialogs/ChangePasswordDialog.vue").then(m => m.default || m)
  },
  {
    name: "settings-components-SettingsPage___en",
    path: "/en/settings/components/SettingsPage",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/SettingsPage.vue").then(m => m.default || m)
  },
  {
    name: "settings-components-SettingsPage___de",
    path: "/settings/components/SettingsPage",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/SettingsPage.vue").then(m => m.default || m)
  },
  {
    name: "settings___en",
    path: "/en/settings",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings___de",
    path: "/settings",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-AddStoreDialog___en",
    path: "/en/store-management/dialogs/AddStoreDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/AddStoreDialog.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-AddStoreDialog___de",
    path: "/store-management/dialogs/AddStoreDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/AddStoreDialog.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step1___en",
    path: "/en/store-management/dialogs/Step1",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step1.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step1___de",
    path: "/store-management/dialogs/Step1",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step1.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step2___en",
    path: "/en/store-management/dialogs/Step2",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step2.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step2___de",
    path: "/store-management/dialogs/Step2",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step2.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step3___en",
    path: "/en/store-management/dialogs/Step3",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step3.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step3___de",
    path: "/store-management/dialogs/Step3",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step3.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step4___en",
    path: "/en/store-management/dialogs/Step4",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step4.vue").then(m => m.default || m)
  },
  {
    name: "store-management-dialogs-Step4___de",
    path: "/store-management/dialogs/Step4",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step4.vue").then(m => m.default || m)
  },
  {
    name: "store-management___en",
    path: "/en/store-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/index.vue").then(m => m.default || m)
  },
  {
    name: "store-management___de",
    path: "/store-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/index.vue").then(m => m.default || m)
  },
  {
    name: "user-administration-dialogs-EditRightsGroupsDialog___en",
    path: "/en/user-administration/dialogs/EditRightsGroupsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditRightsGroupsDialog.vue").then(m => m.default || m)
  },
  {
    name: "user-administration-dialogs-EditRightsGroupsDialog___de",
    path: "/user-administration/dialogs/EditRightsGroupsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditRightsGroupsDialog.vue").then(m => m.default || m)
  },
  {
    name: "user-administration-dialogs-EditUserRightsDialog___en",
    path: "/en/user-administration/dialogs/EditUserRightsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditUserRightsDialog.vue").then(m => m.default || m)
  },
  {
    name: "user-administration-dialogs-EditUserRightsDialog___de",
    path: "/user-administration/dialogs/EditUserRightsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditUserRightsDialog.vue").then(m => m.default || m)
  },
  {
    name: "user-administration___en",
    path: "/en/user-administration",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/index.vue").then(m => m.default || m)
  },
  {
    name: "user-administration___de",
    path: "/user-administration",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/index.vue").then(m => m.default || m)
  }
]